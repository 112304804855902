.PostHeader {
  font-weight: 700;
  .header-content {
    height: 120px;
  }

  .heading-text {
    font-size: 14px;
    line-height: 40px;
    vertical-align: middle;
    text-transform: uppercase;
    &:hover {
      color: $primary;
      text-decoration: none;
    }
  }

  .nav-link {
    font-family: $font-family-montserrat;
    margin: 0 5px;
    text-transform: uppercase;
    font-size: 12px;
    letter-spacing: 1px;
    color: #fff !important;
    cursor: pointer;
    line-height: 30px;
    vertical-align: middle;
    &.active {
      background-color: $primary;
      border-radius: 3px;
      padding: 7px 12px;
      &:hover {
        color: #fff !important;
        cursor: default;
      }
    }
    &:hover {
      color: $primary !important;
    }
  }
}
