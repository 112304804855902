.die-boek {
	.content-wrapper {
		h5 {
			font-size: 1rem;
			font-weight: 400;
			margin: 0;
			padding: 0;
		}
		.voorblad {
			float: left;
			width: 230px;
			margin: 0 35px 10px 0 !important;
		}
	}
}