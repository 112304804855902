.HeadingAddNew {
  display: flex;
  justify-content: space-between;
  i {
    color: $black;
    cursor: pointer;
    transition: all ease-in-out 0.1s;

    &:hover {
      color: rgba(0, 0, 0, 0.5);
    }
  }
}
