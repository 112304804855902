.post {
  position: relative;
  margin: 100px 20px 40px 20px;
  text-align: justify;

  h2 {
    font-size: 2.2em;
    font-weight: 700;
    text-transform: uppercase;
  }

  p.post-subtitle {
    margin: 20px 0 30px;
    font-style: italic;
    font-family: $font-family-serif;
  }

  .btn {
    font-family: $font-family-sans-serif;
    font-weight: 700;
    text-transform: uppercase;
  }
}
