.content-card {
  margin: 30px 0 0 0;

  .card-text {
    word-break: break-all;
  }

  .card-subtitle {
    color: $gray-500;
    font-style: italic;
    margin-bottom: 10px;
    font-weight: 500;
  }

  .button-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    a {
      font-weight: 500;
    }

    a:hover {
      text-decoration: none;
    }
  }
}
