@import "./styles/index.scss";

// PAGES ADMIN
@import "./pages/Admin/Dashboard/Dashboard.scss";
@import "./pages/Admin/DieBoek/DieBoek.scss";
@import "./pages/Admin/Resensies/Resensies.scss";
@import "./pages/Admin/VraDieSkrywer/VraDieSkrywer.scss";
@import "./pages/Admin/SommerNet/SommerNet.scss";
@import "./pages/Admin/Bestel/Bestel.scss";
@import "./pages/Admin/PostEdit/PostEdit.scss";
@import "./pages/Admin/PostNew/PostNew.scss";

// PAGES PUBLIC
@import "./pages/Home/Home.scss";
@import "./pages/Post/Post.scss";
@import "./pages/Login/Login.scss";
@import "./pages/VraDieSkrywer/VraDieSkrywer.scss";

// COMPONENTS
@import "./components/App/App.scss";
@import "./components/FormGenerator/FormGenerator.scss";
@import "./components/AdminWrapper/AdminWrapper.scss";
@import "./components/AdminDataGrid/AdminDataGrid.scss";
@import "./components/Header/Header.scss";
@import "./components/PostHeader/PostHeader.scss";
@import "./components/Footer/Footer.scss";
@import "./components/Banner/Banner.scss";
@import "./components/ContentBlock/ContentBlock.scss";
@import "./components/DataGrid/DataGrid.scss";
@import "./components/ContentCard/ContentCard.scss";
@import "./components/Contact/Contact.scss";
@import "./components/DieBoek/DieBoek.scss";
@import "./components/Resensies/Resensies.scss";
@import "./components/SommerNet/SommerNet.scss";
@import "./components/VraDieSkrywer/VraDieSkrywer.scss";
@import "./components/CategoryFilter/CategoryFilter.scss";
@import "./components/HeadingAddNew/HeadingAddNew.scss";
@import "./components/FileUpload/FileUpload.scss";
