.contact {
  position: relative;
  text-align: center;
  color: #fff;
  background-attachment: scroll;
  background-image: url("../../images/namibia.jpg");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 750px;

  .content-heading {
    span {
      a {
        text-decoration: none;
      }
    }
  }

  .input-field {
    font-size: 1rem;
    margin-bottom: 20px;
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .textarea-field {
    font-size: 1rem;
    margin-bottom: 20px;
    height: 225px;
  }

  .bestel-button {
    text-transform: uppercase;
    font-weight: 700;
  }
}
