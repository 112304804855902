.FileUpload {
  margin-bottom: 20px;

  .btn {
    text-transform: uppercase;
    font-weight: 700;
  }
}

.modal {
  .modal-header {
    text-transform: uppercase;
    font-weight: 700;

    .close {
      padding: 1.2rem;
      opacity: 1;
      &:hover {
        color: $primary;
        opacity: 1;
      }
    }

    .modal-title {
      font-size: 30px;
      opacity: 1;
    }
  }

  .modal-body {
    h5 {
      text-transform: uppercase;
      font-weight: 700;
    }
    hr {
      margin-top: 2.5rem;
      border-top: 2px solid $black;
    }

    p {
      font-style: italic;
      text-align: center;
    }

    .card {
      margin-top: 0.5rem;
      margin-bottom: 1rem;
      padding: 0.5rem;
      text-align: center;

      img {
        width: 100%;
        height: 150px;
        object-fit: cover;
      }

      .media-body {
        width: 100%;
        display: flex;
        justify-content: space-around;
        p {
          color: $primary;
          cursor: pointer;
          font-size: 20px;
          margin: 10px 0 5px 0;
          transition: all 0.3s ease-in-out;

          &:hover {
            color: $black;
          }
        }
      }
    }

    .dropzone {
      cursor: pointer;
      margin-top: 20px;
      margin-bottom: 25px;
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 40px;
      border-width: 2px;
      border-radius: 0.25rem;
      border-color: $black;
      border-style: dashed;
      background-color: #fafafa;
      color: #333333;
      outline: none;
      transition: border 0.24s ease-in-out;
      p {
        margin-top: 12px;
      }
    }

    .dropzone:focus {
      border-color: $primary;
    }

    .dropzone.disabled {
      opacity: 0.6;
    }
  }

  .modal-footer {
    button.btn {
      text-transform: uppercase;
      font-weight: 700;
    }
  }
}
