.DieBoek {
  margin-top: -40px;

  h1 {
    margin: 0;
    text-transform: uppercase;
  }

  .form {
    .form-subtitle {
      margin-top: 10px;
    }
  }
}
