.Login {
  position: relative;
  background-image: url("../../images/main-bg.jpg");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;

  .card {
    margin-top: 20vh;
    text-align: left;

    .card-title {
      font-weight: 700;
      font-size: 24px;
      margin-bottom: 7px;
      text-transform: uppercase;
    }

    .card-subtitle {
      color: $gray-500;
      font-style: italic;
      margin-bottom: 10px;
    }

    .form {
      .form-group {
        .label {
          margin-bottom: 5px;
        }
      }
    }
  }
}
