footer {
	border-top: 1px solid $gray-700;
	height: 100px;
	line-height: 100px;
	vertical-align: middle;
	a:hover {
		text-decoration: none;
	}
	@media (max-width: 767px) {
		height: 30px;
		line-height: 30px;
		padding: 20px 0 80px 0;
	}
}