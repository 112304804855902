.banner {
  position: relative;
  margin-top: 65px;
  text-align: center;
  color: #fff;
  background-attachment: scroll;
  background-image: url("../../images/main-bg.jpg");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 650px;
  .intro-text {
    padding: 170px 0;
    h1 {
      font-family: $font-family-serif;
      font-size: 24px;
      font-style: italic;
      vertical-align: middle;
      margin-bottom: 40px;
      font-weight: 500;
    }
    h2 {
      font-size: 50px;
      margin-bottom: 50px;
      text-transform: uppercase;
      font-family: $font-family-montserrat;
      font-weight: 700;
    }
    .button {
      text-transform: uppercase;
      font-family: $font-family-montserrat;
      font-weight: 700;
      padding: 20px 35px;
      font-size: 18px;
    }
    @media (max-width: 767px) {
      h1 {
        font-size: 18px;
      }
      h2 {
        font-size: 30px;
      }
    }
  }
  @media (max-width: 767px) {
    margin-top: 0px;
  }
}
