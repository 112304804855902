.form {
  position: relative;
  margin: 40px 20px 40px 20px;

  h1 {
    margin-bottom: 10px;
    font-weight: 700;
    text-transform: uppercase;
  }

  p.form-subtitle {
    margin: 20px 0 30px;
    font-style: italic;
    font-family: $font-family-serif;
  }

  p.form-footnote {
    margin-top: 10px;
  }

  textarea {
    height: 300px;
    resize: none;
  }

  label {
    margin: 0;
    text-transform: uppercase;
    font-weight: 700;
  }

  label.checkbox {
    text-transform: none;
    font-weight: 400;
  }

  .btn {
    font-family: $font-family-sans-serif;
    font-weight: 700;
    text-transform: uppercase;
  }

  .form-buttons {
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
  }
}
