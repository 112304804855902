.category-filter {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  button {
    font-weight: 700;
    width: 150px;
    margin: 10px 20px 10px 0;
  }
  button:disabled {
    background-color: $gray-300;
    color: $black;
    opacity: 1;
  }
}
