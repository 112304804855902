.vra-die-skrywer {
	.vra-button{
		text-align: center;
		margin: -40px 0 30px 0;
		.btn {
			width: 150px;
		}
	}
	.vra-niks {
		text-align: center;
		font-style: italic;
		margin-top: 20px;
	}
}