.content-block {
	padding: 175px 0;
	.content-heading {
		text-align: center;
		margin-bottom: 50px;
		h2 {
			text-transform: uppercase;
			font-size: 40px;
		}
		h3 {
			font-family: $font-family-serif;
			font-style: italic;
			color: $gray-500;
			font-weight: normal !important;
			font-size: $font-size-base;
		}
	}
	&.grey {
		background-color: #f7f7f7 !important;
	}
}