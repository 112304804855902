.DataGrid {
  .icon-position-center {
    text-align: center;
  }

  td:last-child {
    padding-right: 0;
  }

  .grid-actions {
    text-align: center;
    text-transform: uppercase;
    letter-spacing: -0.03rem;

    span {
      font-size: small;
      font-weight: 700;
      cursor: pointer;
      background-color: $gray-300;
      border-radius: $btn-border-radius-sm;
      box-shadow: $btn-box-shadow;
      padding: 0.5rem;

      &:last-child {
        margin-left: 1.5rem;
      }

      &:hover {
        text-decoration: underline;
        opacity: 0.7;
      }
    }

    span.item-edit {
      color: $success;
    }
    span.item-delete {
      color: $primary;
    }
  }
}
