/* kaushan-script-regular - latin */
@font-face {
  font-family: 'Kaushan Script';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/kaushan-script-v8-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Kaushan Script'), local('KaushanScript-Regular'),
    url('../fonts/kaushan-script-v8-latin-regular.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('../fonts/kaushan-script-v8-latin-regular.woff2') format('woff2'),
    /* Super Modern Browsers */ url('../fonts/kaushan-script-v8-latin-regular.woff') format('woff'),
    /* Modern Browsers */ url('../fonts/kaushan-script-v8-latin-regular.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('../fonts/kaushan-script-v8-latin-regular.svg#KaushanScript')
      format('svg'); /* Legacy iOS */
}

/* montserrat-regular - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/montserrat-v14-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Montserrat Regular'), local('Montserrat-Regular'),
    url('../fonts/montserrat-v14-latin-regular.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('../fonts/montserrat-v14-latin-regular.woff2') format('woff2'),
    /* Super Modern Browsers */ url('../fonts/montserrat-v14-latin-regular.woff') format('woff'),
    /* Modern Browsers */ url('../fonts/montserrat-v14-latin-regular.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('../fonts/montserrat-v14-latin-regular.svg#Montserrat')
      format('svg'); /* Legacy iOS */
}
/* montserrat-700 - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/montserrat-v14-latin-700.eot'); /* IE9 Compat Modes */
  src: local('Montserrat Bold'), local('Montserrat-Bold'),
    url('../fonts/montserrat-v14-latin-700.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('../fonts/montserrat-v14-latin-700.woff2') format('woff2'),
    /* Super Modern Browsers */ url('../fonts/montserrat-v14-latin-700.woff') format('woff'),
    /* Modern Browsers */ url('../fonts/montserrat-v14-latin-700.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('../fonts/montserrat-v14-latin-700.svg#Montserrat') format('svg'); /* Legacy iOS */
}

@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.7.0/css/all.min.css');
@import './_functions';
@import './_theme';
// @import "~bootstrap/scss/variables";
@import '~bootstrap/scss/mixins';

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #000;
  background: rgba(0, 0, 0, 0.6);
}

.justify-content {
  text-align: justify;
}

.splash-page {
  position: absolute;
  opacity: 0;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  width: 100%;
  height: 100vh;
  color: $white;
  background-image: url('../images/loader.gif');
  background-repeat: no-repeat;
  background-position: center;
  transition: opacity 1s ease-in-out;

  div {
    width: 100%;
    position: absolute;
    text-align: center;
  }
}

.animated {
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

@-webkit-keyframes slideInUp {
  from {
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
    visibility: visible;
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes slideInUp {
  from {
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
    visibility: visible;
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.slideInUp {
  -webkit-animation-name: slideInUp;
  animation-name: slideInUp;
}

.Toaster__message {
  .Toaster__alert {
    background-color: $light;
    padding-left: 50px;
    padding-right: 50px;
    .Toaster__alert_text {
      font-weight: 700;
      text-transform: uppercase;
      font-size: 1rem;
    }
    .Toaster__alert_close {
      font-size: 1.3rem;
      font-weight: 700;
      text-transform: uppercase;
    }
  }
}

@import '~bootstrap/scss/bootstrap';
